<template>
  <div class="order-details">
    <div class="state">
      <div class="title">
        <h3>订单详情</h3>
        <router-link to="/PersonalCenter">返回</router-link>
      </div>
      <div class="order-close" v-if="state == 7">
        <img src="@/assets/images/orderCloseState.png" alt="" />
        <div style="left: 312px">
          <p>等待买家付款</p>
          <p>{{ orderDetails.tradeTime | Time }}</p>
        </div>
        <div style="right: 310px">
          <p>交易关闭</p>
          <p>{{ time2 }}</p>
        </div>
      </div>
      <div class="progress-bar" v-else>
        <img src="@/assets/images/progress-bar.png" alt="" />
        <div style="left: 38px">
          <p>1</p>
          <p>等待买家付款</p>
          <p>{{ orderDetails.createTime | Time }}</p>
        </div>
        <div :class="state >= 2 ? 'line' : ''" style="left: 108px"></div>
        <div style="left: 305px">
          <p :style="{ background: state >= 2 ? '' : '#E8E8E8' }">2</p>
          <p>等待卖家发货</p>
          <p v-if="state > 0">{{ orderDetails.deliveryTime | Time }}</p>
        </div>
        <div :class="state >= 3 ? 'line' : ''" style="left: 376px"></div>

        <div style="left: 573px">
          <p :style="{ background: state >= 3 ? '' : '#E8E8E8' }">3</p>
          <p>等待买家确认收货</p>
          <p v-if="state > 3">{{ orderDetails.confirmTime | Time }}</p>
        </div>
        <div :class="state >= 7 ? 'line' : ''" style="left: 644px"></div>
        <div style="left: 840px">
          <p :style="{ background: state >= 7 ? '' : '#E8E8E8' }">4</p>
          <p>交易成功</p>
          <p v-if="state > 7">2021-12-29 14:42:01</p>
        </div>
      </div>

      <div class="current-state">
        <div class="text">
          <p>
            当前订单状态：<span v-if="state == 0">等待买家付款</span>
            <span v-if="state == 2">等待卖家发货</span
            ><span v-if="state == 6">等待买家确认收货</span
            ><span v-if="state == 5">交易成功 </span>
            <span v-if="state == 7"
              >交易关闭 <i v-if="orderDetails.flag">（买家主动取消）</i
              ><i v-else>（系统超时关闭）</i>
            </span>
          </p>
          <p v-if="state == 0">
            <span>{{ CountDown }}</span
            >，请您尽快完成付款，超时订单将自动取消
          </p>
        </div>
        <div class="btn">
          <button v-if="state == 0" class="btn1" @click="goPay">
            立即支付
          </button>
          <button
            v-if="state == 0 || state == 1"
            class="btn4"
            @click="countermand"
          >
            取消订单
          </button>
          <button v-else-if="state == 2" class="btn2">退款</button>
          <button v-if="state == 6 || state == 5" class="btn1">查看物流</button>
          <button v-if="state == 6 || state == 5" class="btn3">查看保单</button>
          <button v-if="state == 6" class="btn2">确认收货</button>
          <button
            v-else-if="state == 4 || state == 7"
            class="btn4"
            @click="deleteOrder"
          >
            删除订单
          </button>
        </div>
      </div>
    </div>
    <div class="message">
      <div class="nav">
        <ul>
          <li @click="index = 0" :class="index === 0 ? 'pitch-on' : ''">
            订单信息
          </li>
          <li @click="index = 1" :class="index === 1 ? 'pitch-on' : ''">
            物流信息
          </li>
          <li @click="index = 2" :class="index === 2 ? 'pitch-on' : ''">
            支付信息
          </li>
        </ul>
      </div>
      <div class="content order-information" v-show="index === 0">
        <div class="userinfo">
          <ul>
            <li>
              订单号：<span>{{ $route.query.dealNumber }}</span>
            </li>
            <li>
              收货单位：<span>{{
                orderDetails.receivingUnit | consignee
              }}</span>
            </li>
            <li>
              收货地址：<span
                >{{ orderDetails.province }}{{ orderDetails.city
                }}{{ orderDetails.county }}{{ orderDetails.address }}</span
              >
            </li>
            <li>
              收货联系人：<span>{{ orderDetails.consignee }}</span>
            </li>
            <li>
              联系人手机号码：<span>{{ orderDetails.telephone }}</span>
            </li>
          </ul>
          <ul>
            <li>
              卖家店铺：<span class="hover" @click="goStore">{{
                orderDetails.storeName
              }}</span>
            </li>
            <li>
              所属公司：<span>{{ orderDetails.senterpriseName }}</span>
            </li>
            <li>
              客服电话：<span>{{ orderDetails.storeServicePhone }}</span>
            </li>
            <li>收款账号：<span class="hover">点击查看</span></li>
          </ul>
        </div>
        <div class="goods-box">
          <div class="title">
            <span>货品</span>
            <span>数量</span>
            <span>单价（元）</span>
            <span>金额（元）</span>
            <span>货品状态</span>
          </div>
          <div class="cont">
            <div>
              <div
                class="goods"
                v-for="list in orderDetails.orderCommodityListItems"
                :key="list.barcode"
              >
                <img v-lazy="list.filePath" alt="" />
                <div class="details">
                  <div>
                    <span>品名：</span>
                    <h3
                      :title="list.commodityName"
                      @click="goGoods(list.barcode)"
                    >
                      {{ list.commodityName }}
                    </h3>
                  </div>
                  <p>
                    品牌：<span>{{ list.brandName }}</span>
                  </p>
                  <p>
                    规格：<span>{{ list.specsParameter }}</span>
                  </p>
                </div>
                <p class="num">{{ list.quantity }}</p>
                <p class="price">{{ list.sellingPrice | price }}</p>
                <p class="total-price">
                  {{ (list.sellingPrice * list.quantity) | price }}
                </p>
              </div>
            </div>
            <div class="right">
              <p v-if="state == 0">未付款</p>
              <p v-if="state == 2">待发货</p>
              <p v-if="state == 6">待收货</p>
              <p v-if="state == 5">交易成功</p>
              <span v-if="state == 0">{{ CountDown }}</span>
            </div>
          </div>
          <div class="total-amount">
            <div class="left">
              <p>
                <span style="color: #666666">买家留言：</span
                >{{ orderDetails.remarks }}
              </p>
              <!-- <a>[ 补充留言 ]</a> -->
            </div>
            <div class="right">
              <p>
                货品总金额：<span>{{ orderDetails.totalAmount | price }}</span
                >&nbsp;元
              </p>
              <p>运费：另外计算</p>
              <p>已支付金额：<span>0</span>&nbsp;元</p>
            </div>
          </div>
          <div class="waves"></div>
        </div>
      </div>
      <div class="content logistics-information" v-show="index === 1">
        <div class="store">
          <p v-if="orderDetails.waybillNumber">
            本订单共包含<span>1</span>个物流单
          </p>
          <p v-else>暂无该笔订单的物流信息！</p>
          <div>
            <p>
              卖家店铺：<a @click="goStore">{{ orderDetails.storeName }}</a>
            </p>
            <p>
              客服电话：<span>{{ orderDetails.storeServicePhone }}</span>
            </p>
          </div>
        </div>
        <div class="logistics-details" v-if="orderDetails.waybillNumber">
          <div class="title">
            <p>卖家发货</p>
            <p><span>物流状态：</span>已发货</p>
          </div>
          <div class="cont">
            <div class="details">
              <p style="width: 234px">物流编号：<span>WL202112290001</span></p>
              <p style="width: 180px">物流公司：<span>跨越物流</span></p>
              <p style="width: 322px">
                运单号码：<span>{{ orderDetails.waybillNumber }}</span
                ><a>物流跟踪信息</a>
              </p>
              <!-- <p>发货时间：<span>{{logistics[0].uploadDate}}</span></p> -->
            </div>
            <div class="goods">
              <p>
                物流单包含<span>
                  1
                  <!-- {{ orderDetails.orderCommodityListItems.length }}  --> </span
                >个货品
              </p>
              <div class="goods-details">
                <div
                  class="goods-box"
                  v-for="list in orderDetails.orderCommodityListItems"
                  :key="list.barcode"
                >
                  <img v-lazy="list.filePath" alt="" />
                  <div>
                    <p>{{ list.commodityName }}</p>
                    <p>
                      品牌：<span>{{ list.brandName }}</span>
                    </p>
                    <p>
                      规格：<span>{{ list.specsParameter }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="waves"></div>
        </div>
      </div>
      <div class="content payment-information" v-show="index === 2">
        <div class="order-title">
          <p>
            订单号：<span>{{ $route.query.dealNumber }}</span>
          </p>
          <p>
            订单金额：<span>{{ orderDetails.totalAmount }}</span>
          </p>
          <p>
            卖家店铺：<a @click="goStore">{{ orderDetails.storeName }}</a>
          </p>
          <p>
            客服电话：<span>{{ orderDetails.storeServicePhone }}</span>
          </p>
        </div>
        <div class="pay-for-content" v-if="pay.length === 0">
          <div class="pay-title">
            <p>暂无该笔订单的支付信息！</p>
          </div>
        </div>
        <div class="pay-for-content" v-else>
          <div class="pay-title">
            <p><span>支付状态：</span>已支付</p>
          </div>
          <div class="pay-content">
            <p>
              支付方式：<span>{{ pay[0].paymentMethod }}</span>
            </p>
            <p>
              支付金额：<span>{{ pay[0].payAmount }}</span>
            </p>
            <p>付款方：<span>个人</span></p>
            <p>
              支付时间：<span>{{ pay[0].tradeTime | Time }}</span>
            </p>
            <p>
              支付流水号：<span>{{ pay[0].transactionId }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <p><svg-icon icon-class="ic_Tips" />店长请使用PDA终端开展店长业务。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { decimals, countDown, formatDate } from "@/utils/validate";

export default {
  name: "order-details",

  data() {
    return {
      index: 0,
      state: "", //订单状态
      orderDetails: "", //订单详情
      CountDown: "",
      time2: "",
      pay: [],
    };
  },
  filters: {
    price(num) {
      if (num != undefined) {
        return decimals(num);
      }
    },
    consignee(val) {
      if (val) {
        return val;
      } else {
        return "-";
      }
    },
    Time(i) {
      return formatDate(i);
    },
  },
  created() {
    this.state = this.$route.query.state;
    this.DealDetails();
    this.PaymentRecordList();
  },
  beforeDestroy() {
    clearInterval(this.t);
  },
  methods: {
    //订单详情
    async DealDetails() {
      const data = await this.$API.personalUser.DealDetails({
        object: {
          dealNumber: this.$route.query.dealNumber,
        },
      });
      this.orderDetails = data.data.data;
      for (const list of this.orderDetails.orderCommodityListItems) {
        list.filePath = list.filePath.split(",")[0];
      }
      this.WaybillDetails();
      //判断订单是否超时

      if (this.$route.query.state == 0) {
        this.CountDown = countDown(this.orderDetails.tradeTime);
        this.t = setInterval(() => {
          let EndTime = new Date(this.orderDetails.tradeTime); //截止时间
          let NowTime = new Date();
          this.CountDown = countDown(this.orderDetails.tradeTime);

          if (432000000 - (NowTime.getTime() - EndTime.getTime()) < 0) {
            this.$API.personalUser.CancelOrder({
              object: {
                dealNumber: this.$route.query.dealNumber,
              },
            });
            clearInterval(this.t);
            this.$router.push({
              path: "/PersonalCenter",
            });
            // this.CountDown = `0天0小时0分0秒`;
          }
        }, 1000);
      }

      this.time2 = this.orderDetails.flag
        ? formatDate(Number(this.orderDetails.flag))
        : formatDate(this.orderDetails.tradeTime + 432000000);

      console.log(this.orderDetails);
    },
    //查物流
    async WaybillDetails() {
      const data = await this.$API.personalUser.WaybillDetails({
        object: {
          waybillNumber: this.orderDetails.waybillNumber,
        },
      });
      // console.log(data.data.data);
      this.logistics = data.data.data;
    },
    //查支付信息
    async PaymentRecordList() {
      const data = await this.$API.order.paymentRecordList({
        object: {
          dealNumber: this.$route.query.dealNumber,
          enterpriseId: "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.pay = data.data.data;
      this.pay[0].paymentMethod === "pay.alipay.jspay"
        ? (this.pay[0].paymentMethod = "支付宝")
        : "";
      this.pay[0].paymentMethod === "pay.weixin.jspay"
        ? (this.pay[0].paymentMethod = "微信")
        : "";
    },
    //立即支付
    goPay() {
      this.dialogVisible = true;
      return;
      sessionStorage.setItem("Time", this.orderDetails.tradeTime);
      sessionStorage.setItem("totalAmount", this.orderDetails.totalAmount);
      sessionStorage.setItem("orderNum", this.$route.query.dealNumber);

      this.$router.push({
        path: `/Pay/ModeOfPayment?orderNum=${this.$route.query.dealNumber}`,
      });
    },
    //取消订单
    countermand() {
      // console.log(order.dealNumber);
      this.$confirm("您确定要取消当前订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .CancelOrder({
              object: {
                dealNumber: this.$route.query.dealNumber,
                flag: new Date().getTime(),
              },
            })
            .then((data) => {
              this.$message({
                message: "取消成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
              this.$router.push({ path: "/PersonalCenter" });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("取消失败");
            });
        })
        .catch(() => {});
    },
    //删除订单
    deleteOrder() {
      this.$confirm("您确定要删除当前订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .DeleteOrder({
              object: {
                dealNumber: this.$route.query.dealNumber,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
              this.$router.push({ path: "/PersonalCenter" });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    //跳转店铺
    goStore() {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${this.orderDetails.storeId}`,
        query: { enterpriseId: this.orderDetails.senterpriseId },
      });
      window.open(a.href, "_blank");
    },
    //跳转商品
    goGoods(barcode) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${this.orderDetails.storeId}/GoodsDetails/${barcode}`,
        query: { enterpriseId: this.orderDetails.senterpriseId },
      });
      window.open(a.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.order-details {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  .state {
    background: #fff;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      background: #f3f3f3;
      border-bottom: 1px solid #dddddd;
      padding: 0 20px;
      h3 {
        font-size: 17px;
        font-weight: bold;
        line-height: 21px;
        color: #333333;
      }
      a {
        font-size: 14px;
        line-height: 19px;
        color: #666666;
      }
      a:hover {
        color: var(--subjectColor);
      }
    }
    .order-close {
      text-align: center;
      height: 134px;
      padding-top: 30px;
      position: relative;
      border-bottom: 1px solid #e8e8e8;
      div {
        position: absolute;
        top: 74px;
        font-size: 12px;
        p:nth-child(1) {
          color: #333333;
        }
        p:nth-child(2) {
          color: #999999;
        }
      }
    }
    .progress-bar {
      text-align: center;
      position: relative;
      height: 134px;
      padding-top: 30px;
      border-bottom: 1px solid #e8e8e8;
      div {
        position: absolute;
        top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 120px;
        p:nth-child(1) {
          width: 24px;
          height: 24px;
          background: linear-gradient(156deg, #00c45c 0%, #12b15d 100%);
          border-radius: 50%;
          font-size: 18px;
          font-family: SF UI Display;
          font-weight: 600;
          color: #ffffff;
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          margin: 15px 0 4px 0;
        }
        p:nth-child(3) {
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }
      .line {
        width: 246px;
        height: 4px;
        background: linear-gradient(156deg, #00c45c 0%, #12b15d 100%);
        top: 45px;
      }
    }
    .current-state {
      padding: 24px 40px;
      display: flex;
      flex-direction: column;
      .text {
        display: flex;
        align-items: center;
        p:nth-child(1) {
          font-size: 16px;
          color: #333333;
          span {
            color: var(--subjectColor);
            i {
              color: #888888;
            }
          }
        }
        p:nth-child(2) {
          height: 30px;
          margin-left: 20px;
          background: #fff9f2;
          border: 1px solid #ffe5c2;
          font-size: 12px;
          line-height: 28px;
          color: #666666;
          padding: 0 10px;
          span {
            color: #fe3132;
          }
        }
      }
      .btn {
        margin-top: 24px;
        button {
          width: 96px;
          height: 36px;
          border: none;
          outline: none;
          border-radius: 2px;
          font-size: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          margin-right: 20px;
        }
        button:last-child {
          margin: 0;
        }
        .btn1 {
          background: var(--subjectColor);
          color: #fff;
        }
        .btn1:hover {
          background: var(--btnColor);
        }
        .btn2 {
          background: #f5f5f5;
          border: 1px solid #dddddd;
          color: #333;
        }
        .btn2:hover {
          border-color: var(--subjectColor);
          color: var(--subjectColor);
          background: #fff;
        }
        .btn3 {
          background: #ffffff;
          border: 1px solid var(--subjectColor);
          color: var(--subjectColor);
        }
        .btn3:hover {
          background: var(--subjectColor);
          color: #fff;
        }
        .btn4 {
          background: #fff;
          border: 1px solid #dddddd;
          color: #999999;
        }
        .btn4:hover {
          border-color: var(--subjectColor);
          color: var(--subjectColor);
        }
      }
    }
  }
  .message {
    .nav {
      ul {
        display: flex;
        border: 1px solid #dfdfdf;
        border-bottom: none;
        width: 407px;
        height: 42px;
        li:nth-child(1) {
          border-left: none;
        }
        li {
          width: 135px;
          height: 40px;
          background: #f8f8f8;
          border-left: 1px solid #dfdfdf;
          color: #333333;
          text-align: center;
          line-height: 38px;
          font-size: 14px;
          cursor: pointer;
        }
        .pitch-on {
          color: var(--subjectColor);
          font-weight: bold;
          border-top: 2px solid var(--subjectColor);
          background: #fff;
          position: relative;
          top: -1px;
          height: 43px;
        }
      }
    }
    .content {
      background: #fff;
      padding: 20px;
      border: 1px solid #dddddd;
    }
    .order-information {
      display: flex;
      flex-direction: column;
      .userinfo {
        display: flex;
        width: 100%;
        padding-left: 20px;
        ul {
          padding-right: 20px;
          width: 469px;
          li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #888888;
            margin-bottom: 10px;
            line-height: 20px;
            span {
              color: #333333;
            }
            .hover {
              color: #086ac2;
              cursor: pointer;
            }
            .hover:hover {
              color: var(--subjectColor);
            }
          }
        }
      }
      .goods-box {
        border: 1px solid #e2edf8;
        position: relative;
        border-bottom: none;
        .title {
          height: 40px;
          border-bottom: 1px solid #e2edf8;
          line-height: 40px;
          font-size: 14px;
          color: #333333;
          padding: 0 20px;
          background: #f2f7ff;
          span:nth-child(2) {
            margin: 0 101px 0 353px;
          }
          span:nth-child(4) {
            margin: 0 94px 0 80px;
          }
        }
        .cont {
          display: flex;
          .goods {
            display: flex;
            width: 790px;
            border-right: 1px solid #e2edf8;
            padding: 20px;
            font-size: 14px;
            border-bottom: 1px solid #e2edf8;

            img {
              width: 72px;
              height: 72px;
              margin-right: 12px;
            }
            .details {
              width: 262px;
              padding-right: 10px;
              // cursor: default;
              div {
                display: flex;
                margin-bottom: 14px;

                h3 {
                  flex: 1;
                  height: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                }
                h3:hover {
                  color: var(--subjectColor);
                }
              }
              p {
                height: 16px;
                font-size: 12px;
                color: #999999;
                margin-top: 6px;
                span {
                  color: #666666;
                }
              }
            }
            .num {
              width: 100px;
              text-align: center;
            }
            .price {
              margin-left: 22px;
            }
            .total-price {
              font-weight: bold;
              margin-left: 54px;
            }
            > p {
              width: 100px;
              text-align: end;
            }
          }
          .right {
            width: 100%;

            display: flex;
            border-bottom: 1px solid #e2edf8;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
              font-size: 14px;
              line-height: 20px;
              color: #333333;
            }
            span {
              font-size: 12px;
              line-height: 16px;
              color: #fe3132;
            }
          }
        }
        .total-amount {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          .left {
            flex: 1;
            padding-right: 100px;
            a {
              line-height: 28px;
              color: #086ac2;
              cursor: pointer;
            }
          }
          .right {
            line-height: 28px;
            text-align: end;
            p span {
              font-size: 16px;
            }
            p:nth-child(3) span {
              color: #fe3132;
            }
          }
        }
        .waves::before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 100%;
          height: 0.25em;
          background: -webkit-linear-gradient(
              315deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            ),
            -webkit-linear-gradient(45deg, transparent, transparent 45%, #e2edf8, transparent
                  55%, transparent 100%);
          background: linear-gradient(
              135deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            ),
            linear-gradient(
              45deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            );
          background-size: 0.5em 0.5em;
          background-repeat: repeat-x, repeat-x;
        }
      }
    }
    .logistics-information {
      padding: 20px;
      .store {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 14px;
        > p {
          line-height: 20px;
          color: #333333;
          span {
            color: var(--subjectColor);
          }
        }
        div {
          display: flex;
          p {
            margin-left: 40px;
            color: #888888;
            span {
              color: #333333;
            }
            a {
              color: #086ac2;
              cursor: pointer;
            }
          }
        }
      }
      .logistics-details {
        margin-top: 20px;
        border: 1px solid #e2edf8;
        position: relative;
        border-bottom: none;
        .title {
          display: flex;
          height: 40px;
          align-items: center;
          background: #f2f7ff;
          padding: 0 20px;
          font-size: 14px;
          border-bottom: 1px solid #e2edf8;
          color: #333333;
          p {
            margin-right: 40px;
            span {
              color: #888888;
            }
          }
        }
        .cont {
          padding: 20px;
          font-size: 14px;
          .details {
            display: flex;
            margin-bottom: 20px;
            font-size: 12px;
            p {
              color: #888888;
              span {
                color: #333333;
              }
              a {
                color: #086ac2;
                margin-left: 6px;
              }
            }
          }
          .goods {
            > p {
              font-size: 14px;
              line-height: 20px;
              color: #333333;

              span {
                color: var(--subjectColor);
              }
            }
            .goods-details {
              display: flex;
              flex-wrap: wrap;
              .goods-box {
                width: 305px;
                display: flex;
                padding-right: 30px;
                margin-top: 12px;
                img {
                  width: 72px;
                  height: 72px;
                }
                div {
                  margin-left: 12px;

                  p:nth-child(1) {
                    font-size: 14px;
                    line-height: 20px;
                    color: #333333;
                    margin-bottom: 14px;
                    margin-top: 0;
                  }
                  p {
                    color: #999;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 190px;
                    line-height: 16px;
                    margin-top: 6px;
                    span {
                      color: #666666;
                    }
                  }
                }
              }
            }
          }
        }
        .waves::before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 100%;
          height: 0.25em;
          background: -webkit-linear-gradient(
              315deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            ),
            -webkit-linear-gradient(45deg, transparent, transparent 45%, #e2edf8, transparent
                  55%, transparent 100%);
          background: linear-gradient(
              135deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            ),
            linear-gradient(
              45deg,
              transparent,
              transparent 45%,
              #e2edf8,
              transparent 55%,
              transparent 100%
            );
          background-size: 0.5em 0.5em;
          background-repeat: repeat-x, repeat-x;
        }
      }
    }
    .payment-information {
      padding: 20px;
      .order-title {
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        p {
          font-size: 14px;
          line-height: 20px;
          color: #888;
          width: auto;

          span {
            color: #333;
          }
          a {
            color: #086ac2;
            cursor: pointer;
          }
        }
      }
      .pay-for-content {
        border: 1px solid #e2edf8;
        margin: 20px 0 10px 0;
        .pay-title {
          height: 40px;
          background: #f2f7ff;
          display: flex;
          padding: 0 20px;
          align-items: center;
          p {
            color: #333333;
            font-size: 14px;
            margin-right: 40px;
            span {
              color: #888888;
            }
          }
        }
        .pay-content {
          padding: 20px 20px 14px;
          display: flex;
          flex-wrap: wrap;
          p {
            flex: 25%;
            margin-bottom: 10px;
            font-size: 14px;
            color: #888888;
            span {
              color: #333333;
            }
            a {
              color: #086ac2;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__wrapper {
    .el-dialog__header {
      padding: 12px 20px;
    }
    .el-dialog__body{
      padding-top: 0;
      
      .svg-icon{
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
}
</style>